@use "../abstracts/variables" as *;

.featured-boxes {
  border-top: 2.5rem solid $color-primary;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;

  // Tablet breakpoint
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    //gap: 1.5rem;
  }

  // When 2 columns switch to 1
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    //gap: 1.5rem;
  }

  // Mobile breakpoint
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    //padding: 2rem 1rem;
  }
}

.featured-box {
  padding: 8rem 5rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #fff;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }

  // Icon styles
  &__icon {
    width: 64px;
    height: 64px;
    margin: 0 auto 1.5rem;

    svg {
      width: 100%;
      height: 100%;
      fill: $color-primary;
    }
  }

  // Heading styles
  &__title {
    font-size: 1.2rem;
    letter-spacing: 1px;
    color: $color-primary;
    margin-bottom: 1rem;
    font-weight: 600;
    text-transform: uppercase;

    @media (max-width: 900px) {
      font-size: 2.3rem;
    }
  }

  // Content styles
  &__content {
    font-size: 1.4rem;
    line-height: 1.6;
    color: #666;
    margin-bottom: 1.5rem;

    @media (max-width: 900px) {
      font-size: 2rem;
      line-height: 1.5;
    }
  }

  // Box variations
  &--primary-light {
    background-color: $color-primary-light;

    .featured-box__icon svg {
      fill: $color-white;
    }

    .featured-box__title {
      color: $color-secondary;
    }

    .featured-box__content {
      color: #fff;

      a {
        color: $color-white;
        text-decoration: none;
        font-size: 1.8rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &--primary {
    background-color: $color-primary;

    .featured-box__icon svg {
      fill: $color-white;
    }

    .featured-box__title {
      color: $color-secondary;
    }

    .featured-box__content {
      color: #fff;

      a {
        color: $color-white;
        text-decoration: none;
        font-size: 1.8rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  // Responsive adjustments
  @media (max-width: 900px) {
    padding: 15rem 5rem;
  }

  @media (max-width: 900px) {
    padding: 15rem 5rem;
  }
}

// Optional: Animation for initial load
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.featured-box {
  animation: fadeInUp 0.6s ease-out forwards;

  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      animation-delay: $i * 0.2s;
    }
  }
}
