@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.home-hero {
  position: relative;
  width: 100%;
  height: 85vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to left,
      rgba(29, 151, 108, 0.9) /* fully transparent */,
      rgba(147, 249, 185, 0) /* semi-transparent */
    );
    z-index: 0;
  }

  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    text-align: center;
    padding: 0 2rem;

    p {
      margin-bottom: 0;
    }
  }

  .hero-buttons {
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    display: flex;
    gap: 2rem;
    width: 100%;
    max-width: 600px;
  }

  &__signature {
    position: absolute;
    z-index: 1;
    height: auto;
    width: 250px;
    bottom: 2rem;
    right: 2rem;
    opacity: 0.9;

    @media (max-width: 768px) {
      right: 50%;
      transform: translateX(50%);
      width: 250px;
    }
  }
}
