@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.navbar {
  position: fixed;
  top: 3rem;
  left: 0;
  width: 100%;
  height: 8rem;
  background-color: $color-white;
  box-shadow: 0 2px 5px rgba($color-black, 0.1);
  transition: all 0.3s ease-in-out;
  z-index: 1000;

  @include respond(phone) {
    top: 0;
  }

  // Scrolled state
  &.scrolled {
    height: 6rem;
    top: 0;
    transition: height 0.3s ease-in-out;
  }

  // Container
  &__container {
    max-width: 120rem;
    height: 100%;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: padding 0.3s ease-in-out;

    .scrolled & {
      padding: 0.5rem 2rem;
    }
  }

  // Logo
  &__logo {
    height: 100%;
    max-height: 6rem;
    padding: 0.5rem 0;

    img {
      height: 100%;
      width: auto;
      object-fit: contain;
    }

    .scrolled & {
      max-height: 4.5rem;
    }
  }

  // Main menu
  &__menu {
    display: flex;
    height: 100%;
    margin-left: auto;
    margin-right: 1.5rem;
    list-style: none;
    align-items: center;
    transition: height 0.3s ease-in-out;

    @include respond(tab-port) {
      @include hide-element;
      display: none;
      flex-direction: column;
      position: fixed;
      top: 8rem;
      left: 0;
      width: 100%;
      height: auto;
      padding: 0;
      background-color: $color-primary;
      box-shadow: 0 2px 5px rgba($color-black, 0.1);
      transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);

      @media (min-width: 600px) and (max-width: 900px) {
        top: 10rem;
      }

      &.active {
        @include show-element;
        display: block;
        top: 8rem;
        padding: 2rem;

        @media (min-width: 600px) and (max-width: 900px) {
          top: 10rem;
        }
      }

      .scrolled & {
        top: 6rem;

        &.active {
          top: 6rem;

          @media (min-width: 600px) and (max-width: 900px) {
            //top: 8rem;
          }
        }
      }
    }
  }

  // Extra elements container
  &_extras {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  // Hamburger menu
  &__hamburger {
    display: none;
    cursor: pointer;
    margin-left: 1rem;

    @include respond(tab-port) {
      display: flex;
      align-items: center;
      width: 3rem;
      height: 2.4rem;
      position: relative;
      order: 1;

      span {
        position: absolute;
        height: 0.3rem;
        width: 100%;
        background: $color-secondary;
        border-radius: 0.3rem;
        transition: all 0.3s ease;

        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          top: 50%;
          transform: translateY(-50%);
        }
        &:nth-child(3) {
          bottom: 0;
        }
      }

      &.active span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 50%;
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          bottom: 40%;
        }
      }
    }
  }

  // Menu items
  &__item {
    position: relative;
    height: 100%;
    margin-left: 2rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    transition: height 0.3s ease-in-out;

    @include respond(tab-port) {
      flex-direction: column;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 1rem 0;
      cursor: pointer;
    }

    &:hover::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 10px;
      background-color: transparent;
    }
  }

  // Menu links
  &__link {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
    color: $color-grey-dark-3;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 700;
    letter-spacing: 0.5px;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $color-primary-light;
    }

    @include respond(tab-port) {
      color: $color-white;
      font-size: 1.6rem;
      display: block;
      width: 100%;
      height: auto;
      padding: 1rem 0;

      &:hover {
        color: $color-white;
      }
    }

    @include respond(phone) {
      color: $color-white;
      font-size: 1.6rem;

      &:hover {
        color: $color-white;
      }
    }

    .dropdown-icon {
      margin-left: 0.5rem;
      font-size: 0.8rem;
    }
  }

  // Submenu
  &__submenu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 230px;
    padding: 1rem 0;
    background-color: $color-white;
    box-shadow: 0 2px 5px rgba($color-black, 0.1);
    list-style: none;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
    @include hide-element;

    @include respond(tab-port) {
      position: static;
      min-width: 100%;
      height: 0;
      padding: 0 0 0 2rem;
      box-shadow: none;
      transition: all 0.3s ease;
      max-height: 300px;
      overflow-y: auto;

      &.active {
        @include show-element;
        transform: translateY(0);
        height: auto;
      }
    }

    .navbar__item {
      margin: 0;
      height: auto;
    }

    .navbar__link {
      display: block;
      height: auto;
      padding: 0.8rem 1.5rem;
      color: $color-secondary;
      font-size: 1.1rem;
      opacity: 0;
      transform: translateX(-10px);
      transition: opacity 0.3s ease, transform 0.3s ease;

      @include respond(tab-port) {
        font-size: 1.6rem;
      }

      @include respond(phone) {
        font-size: 1.6rem;
      }

      &:hover {
        background-color: $color-primary-light;
        color: $color-white;
      }

      @include respond(tab-port) {
        opacity: 1;
        transform: none;
        padding: 0.8rem 0;
        color: $color-primary;
      }
    }
  }

  // Submenu interactions
  &__item:hover > &__submenu,
  &__item.active > &__submenu {
    @include show-element;
    transform: translateY(0);

    .navbar__link {
      color: $color-primary;
      opacity: 1;
      transform: translateX(0);
      transition-delay: 0.1s;
      width: 100%;

      &:hover {
        color: $color-white;
      }
    }
  }
}
