@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.topbar {
  height: 3rem;
  background: $gradient-green;
  color: $color-white;
  padding: 0.5rem 0;
  transition: transform 0.1s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;

  @include respond(phone) {
    display: none;
  }

  &__container {
    max-width: 120rem;
    margin: 0 auto;
    padding: 0 3rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__info {
    margin-left: 2rem;
    font-size: 1.2rem;

    a {
      //text-decoration: none;
      color: $color-white;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}
