@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1 rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

  @include respond(tab-land) {
    // width < 1200px?
    font-size: 56.25%; //1 rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    // width < 900px?
    font-size: 50%; //1 rem = 8px, 8/16 = 50%
  }

  @include respond(phone) {
    // width < 900px?
    font-size: 48%; //1 rem = 7px, 7/16 = 43.75%
  }

  @include respond(big-desktop) {
    // width > 1800px?
    font-size: 75%; // 1 rem = 12, 12/16 = 75%
  }

  // alternative way of defining breakpoints
  /*
    @include break(md) {
        ...
      }
    */
}

body {
  box-sizing: border-box;

  @include respond(tab-port) {
    padding: 0;
  }

  ::selection {
    background-color: $color-primary;
    color: $color-white;
  }
}
