@use "../abstracts/variables" as *;

.footer {
  background-color: $color-primary;
  position: relative;

  // Add overlay similar to hero
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  // Update background pattern opacity and z-index
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cpattern id='cube' patternUnits='userSpaceOnUse' width='50' height='50' patternTransform='scale(2) rotate(0)'%3E%3Crect width='50' height='50' fill='%23410179ff'/%3E%3Cpath d='M25 0L50 15L25 30L0 15zM0 15L0 35L25 50L25 30M50 15L50 35L25 50L25 30' stroke='%23ffffff' stroke-width='1' stroke-opacity='0.5' fill='none'/%3E%3C/pattern%3E%3C/defs%3E%3Crect width='100%' height='100%' fill='url(%23cube)'/%3E%3C/svg%3E");
    pointer-events: none;
    z-index: 1;
  }

  // Update z-index for content
  > *:not(.footer__background) {
    position: relative;
    z-index: 2;
  }

  // Logo styles
  &__logo {
    text-align: center;
    margin-bottom: 4rem;
    padding-top: 4rem;

    img {
      width: 20rem;
      height: auto;
    }
  }

  // Grid container
  &__content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  }

  // Column headings
  &__heading {
    color: #fff; // Golden yellow color
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 1.6rem;
    }
  }

  // Address section
  &__address {
    line-height: 1.6;
    text-align: center;
    color: #ffffff;

    p {
      margin: 0.5rem 0;
      font-size: 1.3rem;

      @media (max-width: 768px) {
        font-size: 1.7rem;
      }
    }
  }

  // Working hours
  &__hours {
    margin-top: 2rem;
    text-align: center;
    color: #ffffff;

    p {
      margin: 0.5rem 0;
      font-size: 1.3rem;

      @media (max-width: 768px) {
        font-size: 1.7rem;
      }
    }
  }

  // Links styling
  &__links {
    text-align: center;
    a {
      display: block;
      color: #ffffff;
      text-decoration: none;
      margin: 0.5rem 0;
      transition: color 0.3s ease;
      font-size: 1.3rem;

      @media (max-width: 768px) {
        font-size: 1.7rem;
      }

      &:hover {
        color: #ffd700;
      }
    }
  }

  // Contact information
  &__contact {
    text-align: center;
    a {
      display: block;
      color: #ffffff;
      text-decoration: none;
      margin: 0.5rem 0;
      transition: color 0.3s ease;
      font-size: 1.3rem;

      @media (max-width: 768px) {
        font-size: 1.7rem;
      }

      &:hover {
        color: #ffd700;
      }
    }
  }

  // Copyright section
  &__copyright {
    text-align: center;
    color: #fff;
    margin-top: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 0.9rem;

    a {
      color: #ffd700;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
