@use "../abstracts/variables" as *;

// Section base styles
.section {
  width: 100%;
  padding: 11rem 0;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 8rem 0;
  }

  @media screen and (max-width: 480px) {
    padding: 6rem 0;
  }
}

// Modifier for full-width sections
.section--full {
  padding: 0;
}

.section--bg-primary {
  background-color: $color-primary;
}

.section--bg-gray {
  background-color: $color-grey-light-1;
}

.section--bg-yellow {
  background-color: #fffdf5;
}

// New pattern section modifier
.section--pattern {
  background-color: $color-primary;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  // Pattern background element
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cpattern id='cube' patternUnits='userSpaceOnUse' width='50' height='50' patternTransform='scale(2) rotate(0)'%3E%3Crect width='50' height='50' fill='%23410179ff'/%3E%3Cpath d='M25 0L50 15L25 30L0 15zM0 15L0 35L25 50L25 30M50 15L50 35L25 50L25 30' stroke='%23ffffff' stroke-width='1' stroke-opacity='0.5' fill='none'/%3E%3C/pattern%3E%3C/defs%3E%3Crect width='100%' height='100%' fill='url(%23cube)'/%3E%3C/svg%3E");
    pointer-events: none;
  }

  // Make sure content stays above the overlays
  > * {
    position: relative;
    z-index: 2;
  }
}

// Modifier for sections with vertical spacing
.section--spacing {
  margin: 4rem 0;

  @media screen and (max-width: 768px) {
    margin: 3rem 0;
  }

  @media screen and (max-width: 480px) {
    margin: 2rem 0;
  }
}
