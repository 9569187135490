.article-card {
  display: flex;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  min-height: 280px;
  margin-bottom: 3rem;
  align-items: stretch;

  .image-wrapper {
    flex: 0 0 50%;
    position: relative;
    overflow: hidden;
    aspect-ratio: 16/9;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      display: block;
    }
  }

  .content {
    flex: 1;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 1rem;
      color: #333;
    }

    a {
      text-decoration: none;
    }

    .btn {
      align-self: flex-start;
      width: auto;
    }
  }

  &--reverse {
    flex-direction: row-reverse;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  // Responsive layout
  @media (max-width: 768px) {
    flex-direction: column;

    .image-wrapper {
      flex: 0 0 auto;
      position: relative;
      aspect-ratio: 16/9;
    }

    .content {
      h2 {
        font-size: 3rem;
      }
    }
  }
}
