@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.language {
  &-btn {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 0.8rem;
    border: 1px solid $color-secondary;
    border-radius: 9999px;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    position: relative;

    @include respond(tab-port) {
      padding: 0.8rem 1.2rem;
    }

    @include respond(phone) {
      padding: 1rem 1.5rem;
    }

    &:hover {
      box-shadow: 0 4px 6px rgba($color-black, 0.1);
    }
  }

  &-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.8rem;
    border-radius: 50%;
    @include respond(tab-port) {
      width: 2.5rem;
      height: 2.5rem;
    }
    @include respond(phone) {
      width: 3rem;
      height: 3rem;
    }
  }

  &-dropdown {
    position: absolute;
    top: 0;
    margin-left: 3rem;
    padding: 0.8rem 0;
    width: 15rem;
    background-color: $color-white;
    box-shadow: 0 4px 12px rgba($color-black, 0.1);
    transform: translateX(-50%);
    z-index: 99998;
    display: none;

    &::before {
      content: "";
      position: absolute;
      top: -0.8rem;
      left: 50%;
      transform: translateX(-50%);
      border: {
        left: 0.8rem solid transparent;
        right: 0.8rem solid transparent;
        bottom: 0.8rem solid $color-white;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      padding: 1rem 1.6rem;

      a {
        display: flex;
        align-items: center;
        color: $color-primary;
        font-size: 1.1rem;
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;

        @include respond(phone) {
          font-size: 1.5rem;
        }

        &:hover {
          color: $color-primary-light;
        }
      }
    }

    .language-btn:hover & {
      display: block;
    }
  }
}
