.article-content {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem 1.5rem;

  &__intro {
    margin-top: 4rem;
    margin-bottom: 5rem;
    text-align: center;
    p {
      font-size: 2rem;

      @media (max-width: 768px) {
        font-size: 2.2rem;
        line-height: 1.8;
      }
    }
  }

  &__header {
    margin-bottom: 3rem;
    text-align: center;

    h1 {
      font-size: 2.5rem;
      line-height: 1.2;
      margin-bottom: 1rem;
      color: var(--color-primary);

      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }

    .meta {
      font-size: 0.9rem;
      color: #666;
      margin-bottom: 2rem;
    }
  }

  &__featured-image {
    width: 100%;
    height: 400px;
    margin-bottom: 3rem;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.02);
      }
    }

    @media (max-width: 768px) {
      height: 300px;
    }

    @media (max-width: 480px) {
      height: 200px;
    }

    &--team {
      height: 300px;
      width: 300px;
      margin: 0 auto 3rem;
      border-radius: 50%;

      img {
        border-radius: 50%;
      }

      @media (max-width: 768px) {
        height: 250px;
        width: 250px;
      }

      @media (max-width: 480px) {
        height: 200px;
        width: 200px;
      }
    }
  }

  &__body {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #333;

    p {
      margin-bottom: 1.5rem;
    }

    h2 {
      font-size: 1.8rem;
      color: var(--color-primary);
      margin: 2.5rem 0 1.5rem;

      @media (max-width: 768px) {
        font-size: 2.3rem;
      }
    }

    h3 {
      font-size: 1.4rem;
      color: var(--color-primary);
      margin: 2rem 0 1rem;

      @media (max-width: 768px) {
        font-size: 1.3rem;
      }
    }

    ul,
    ol {
      margin: 1.5rem 0;
      padding-left: 2rem;

      li {
        margin-bottom: 0.5rem;
      }
    }

    blockquote {
      margin: 2rem 0;
      padding: 1.5rem;
      border-left: 4px solid var(--color-primary);
      background-color: #f8f8f8;
      font-style: italic;
    }

    img {
      max-width: 100%;
      height: auto;
      border-radius: 4px;
      margin: 2rem 0;
    }

    a {
      color: var(--color-primary);
      text-decoration: none;
      border-bottom: 1px solid transparent;
      transition: border-color 0.3s ease;

      &:hover {
        border-bottom-color: var(--color-primary);
      }
    }

    code {
      background-color: #f5f5f5;
      padding: 0.2em 0.4em;
      border-radius: 3px;
      font-family: monospace;
    }

    pre {
      background-color: #f5f5f5;
      padding: 1rem;
      border-radius: 4px;
      overflow-x: auto;
      margin: 1.5rem 0;

      code {
        background-color: transparent;
        padding: 0;
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin: 2rem 0;

      th,
      td {
        padding: 0.75rem;
        border: 1px solid #ddd;
        text-align: left;
      }

      th {
        background-color: #f5f5f5;
        font-weight: bold;
      }

      tr:nth-child(even) {
        background-color: #fafafa;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 1.5rem 1rem;
  }
}

.fr-video {
  width: 100%;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
}

iframe {
  width: 100%;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
}
