@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.about-us {
  &__label {
    font-size: 1.2rem;
    font-weight: 500;
    color: $color-secondary;
    letter-spacing: 1px;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  &__heading {
    font-size: 3.2rem;
    line-height: 1.3;
    margin-top: 2rem;
    font-weight: 400;
    display: inline-block;
    transition: all 0.2s;
    color: #1f1f1f;

    @media screen and (max-width: 992px) {
      font-size: 3rem;
    }

    @media screen and (max-width: 480px) {
      font-size: 2.8rem;
      line-height: 1.4;
    }
  }

  &__image-container {
    overflow: hidden;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    margin-bottom: 0;

    @media screen and (max-width: 992px) {
      margin-bottom: 5rem;
    }

    @media screen and (max-width: 480px) {
      margin-bottom: 10rem;
    }
  }

  &__image {
    width: 100%;
    object-fit: cover;
    border-radius: 2rem;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.2);
    }
  }
}
