@use "../abstracts/grid-mixins" as *;
@use "../abstracts/grid-variables" as *;

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: $grid-gutter;
  padding-left: $grid-gutter;

  @media screen and (min-width: 576px) {
    padding-right: $grid-gutter / 2;
    padding-left: $grid-gutter / 2;
  }

  @each $breakpoint, $max-width in $container-max-widths {
    @include breakpoint-up($breakpoint) {
      max-width: $max-width;
    }
  }
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: $grid-gutter;
  padding-left: $grid-gutter;

  @media screen and (min-width: 576px) {
    padding-right: $grid-gutter / 2;
    padding-left: $grid-gutter / 2;
  }
}

@include make-grid-columns;
