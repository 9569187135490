.team-carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 20px 0;

  &__container {
    display: flex;
    transition: transform 0.3s ease-in-out;
    width: 100%;
  }

  &__slide {
    background-color: #fff;
    padding: 3rem;
    border-radius: 1rem;
    flex-shrink: 0;
    width: calc((100% - 60px) / 3);
    margin-right: 30px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .member-image {
      width: 250px;
      height: 250px;
      border-radius: 50%;
      margin: 0 auto 15px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .info {
      min-height: 45px;
    }

    h3 {
      margin: 10px 0;
      font-size: 1.2rem;
    }

    p {
      color: #666;
      font-size: 1.3rem;
    }
  }

  &__arrow {
    position: absolute;
    top: 47.5%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f5f5f5;
    }

    svg {
      width: 24px;
      height: 24px;
      color: #4b0082;
    }

    &--prev {
      left: 30px;
    }

    &--next {
      right: 30px;
    }
  }

  &__dots {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 40px;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ddd;
      border: none;
      cursor: pointer;
      padding: 0;
      transition: background-color 0.3s;

      &.active {
        background: #ffd700;
      }

      &:hover:not(.active) {
        background: #ccc;
      }
    }
  }

  @media (max-width: 992px) {
    &__slide {
      width: calc((100% - 20px) / 2);
      margin-right: 20px;

      .member-image {
        width: 200px;
        height: 200px;
      }
    }
  }

  @media (max-width: 576px) {
    &__slide {
      width: 100%;
      margin-right: 10px;

      .member-image {
        width: 180px;
        height: 180px;
      }
    }
  }

  @media (hover: none) and (pointer: coarse) {
    &__arrow {
      display: none;
    }
  }
}
