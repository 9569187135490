@use "sass:math";
@use "sass:map";
@use "grid-variables" as *;

// Media query mixin
@mixin breakpoint-up($breakpoint) {
  @if map.has-key($grid-breakpoints, $breakpoint) {
    @media (min-width: map.get($grid-breakpoints, $breakpoint)) {
      @content;
    }
  }
}

// Generate column classes
@mixin make-col($size) {
  flex: 0 0 auto;
  @if $size and $grid-columns {
    width: math.percentage(math.div($size, $grid-columns));
  }
}

// Generate grid columns
@mixin make-grid-columns {
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: math.div($grid-gutter, -2);
    margin-left: math.div($grid-gutter, -2);
  }

  [class^="col-"] {
    padding-right: math.div($grid-gutter, 2);
    padding-left: math.div($grid-gutter, 2);
  }

  @each $breakpoint in map.keys($grid-breakpoints) {
    @include breakpoint-up($breakpoint) {
      @for $i from 1 through $grid-columns {
        .col-#{$breakpoint}-#{$i} {
          @include make-col($i);
        }
      }
    }
  }
}
