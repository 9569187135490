@use "../abstracts/variables" as *;

.masonry-gallery {
  columns: 4;
  column-gap: 15px;
  padding: 15px;

  .image-item {
    break-inside: avoid;
    margin-bottom: 15px;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
    cursor: pointer;
    transition: transform 0.3s ease;
    min-height: 100px;
    background: #f0f0f0;

    &.loading {
      opacity: 0.5;
    }

    &:hover {
      transform: scale(1.02);
    }
  }
}

.masonry-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;

  &.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content {
    position: relative;
    max-width: min(1200px, 90%);
    max-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    img {
      max-width: 100%;
      max-height: 90vh;
      object-fit: contain;
      width: auto;
      height: auto;
      background: #f0f0f0;
    }
  }

  .close-btn {
    position: fixed;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
    color: rgba(255, 255, 255, 0.8);
    font-size: 32px;
    cursor: pointer;
    padding: 5px 10px;
    z-index: 1001;
    transition: all 0.2s ease;
    line-height: 1;

    &:hover {
      color: #fff;
      transform: scale(1.1);
    }
  }

  .nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba($color-primary, 0.8);
    border: none;
    color: white;
    font-size: 24px;
    padding: 0;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 50%;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: rgba($color-primary, 0.95);
      transform: translateY(-50%) scale(1.1);
    }

    &.prev-btn {
      left: 20px;
    }

    &.next-btn {
      right: 20px;
    }

    svg {
      width: 24px;
      height: 24px;
      fill: none;
      stroke: currentColor;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
    }

    @media (max-width: 768px) {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .masonry-modal .modal-content {
    max-width: min(1000px, 90%);
  }
}

@media (max-width: 768px) {
  .masonry-gallery {
    columns: 2;
    column-gap: 10px;
  }

  .masonry-modal {
    .close-btn {
      top: 15px;
      right: 15px;
      font-size: 28px;
      padding: 5px 8px;
    }

    .nav-btn {
      width: 40px;
      height: 40px;
      font-size: 20px;

      &.prev-btn {
        left: 10px;
      }

      &.next-btn {
        right: 10px;
      }
    }

    .modal-content {
      max-width: 95%;
    }
  }
}

@media (max-width: 480px) {
  .masonry-gallery {
    columns: 1;
  }
}
