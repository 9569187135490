@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}
.u-margin-bottom-medium {
  margin-bottom: 4rem !important;

  @include respond(tab-port) {
    margin-bottom: 3rem !important;
  }
}
.u-margin-bottom-big {
  margin-bottom: 8rem !important;

  @include respond(tab-port) {
    margin-bottom: 5rem !important;
  }
}

.u-margin-top-big {
  margin-top: 8rem !important;
}
.u-margin-top-huge {
  margin-top: 10rem;
}

.u-margin-top-small {
  margin-top: 1rem;
}

.h-5 {
  height: 2rem;
}

.w-5 {
  width: 2rem;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.u-flex-start {
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.bt-1 {
  border-top: 1px solid rgba($color-black, 0.1);
}

.text-center {
  text-align: center !important;
}

.text-white {
  color: $color-white !important;
}
