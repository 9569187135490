@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: $color-grey-dark-3;
}

.heading-primary {
  backface-visibility: hidden;
  font-size: 7rem;
  line-height: 1.1;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 500;

  // Apply animation to the entire heading
  animation: zoomIn 0.5s;

  @include respond(phone) {
    font-size: 5rem;
  }

  &--main {
    color: $color-white;
  }

  &--sub {
    color: $color-white;
  }
}

.section-heading {
  font-size: 2.5rem;
  padding-left: 10rem;
  padding-right: 10rem;
  line-height: 1.2;
  margin-top: 2rem;
  font-weight: 400;
  display: inline-block;
  transition: all 0.2s;
  color: #1f1f1f;

  @media screen and (max-width: 992px) {
    font-size: 3rem;
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (max-width: 480px) {
    font-size: 2.8rem;
    line-height: 1.4;
    padding-left: 0;
    padding-right: 0;
  }
}

.title-hr {
  position: relative; // Ensure the pseudo-element is positioned relative to this element
  width: 4rem;
  margin: 1.5rem auto 1.5rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;

  &::after {
    content: ""; // Required for pseudo-elements
    position: absolute;
    left: 0; // Center the line
    right: 0; // Center the line
    bottom: 0; // Position it at the bottom of the title-hr
    height: 0.1px; // Set the height to create a thin line
    background-color: #333; // Use the desired color
    transition: all 0.3s ease; // Optional: add transition for hover effects
  }
}

.section-heading:hover + .title-hr {
  width: 6rem;
}

.heading-secondary {
  font-size: 3.5rem;
  font-weight: 400;
  display: inline-block;
  transition: all 0.2s;

  @include respond(tab-port) {
    font-size: 3rem;
  }

  @include respond(phone) {
    font-size: 2.5rem;
  }
}

.heading-tertiary {
  font-size: $default-font-size;
  color: $color-white;
  letter-spacing: 1px;
}

p,
ul,
li,
ol {
  font-size: map-get($font-size, desktop);

  @media screen and (max-width: 992px) {
    font-size: 1.9rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 2.2rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
}

p {
  &:not(:last-child) {
    margin-bottom: 3rem;

    @media screen and (max-width: 768px) {
      margin-bottom: 2.5rem;
    }
  }
}

b,
strong {
  font-weight: 600;
}

.section-hr {
  width: 100%;
  height: 1px;
  background: rgba($color-black, 0.1);
  border: none;
  margin: 3rem 0;
  transition: opacity 0.3s ease;

  @include respond(tab-port) {
    margin: 2.5rem 0;
  }

  @include respond(phone) {
    margin: 2rem 0;
  }
}
