@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.btn {
  &,
  &:link,
  &:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.2rem 2.2rem;
    display: inline-block;
    border-radius: 10px;
    font-weight: 700;
    transition: all 0.2s;
    position: relative;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
    overflow: hidden;

    @include respond(tab-port) {
      padding: 1.5rem 2.5rem;
      font-size: 1.9rem;
    }

    @include respond(phone) {
      padding: 1.6rem 2.6rem;
      font-size: 1.6rem;
    }
  }

  &:hover {
    transform: translateY(-$size-small);

    &::before {
      animation: shine 1.1s ease-in-out;
    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-0.1rem);
    box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
  }

  &--white {
    background-color: $color-white;
    color: $color-primary;

    &::after {
      background-color: $color-white;
    }
  }

  &--primary {
    background-color: $color-primary;
    color: $color-white;

    &::after {
      background-color: $color-primary;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 90%;
    height: 100%;
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(255, 255, 255, 0.3) 50%,
      transparent 100%
    );
    transform: skewX(-25deg);
  }

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
  }

  &--animated {
    animation: moveInBottom 0.5s ease-out 0.75s;
    animation-fill-mode: backwards;
  }
}

.btn-text {
  &:link,
  &:visited {
    font-size: $default-font-size;
    color: $color-primary;
    display: inline-block;
    text-decoration: none;
    border-bottom: 0.1rem solid $color-primary;
    padding: $size-small;
    transition: all 0.2s;
  }

  &:hover {
    background-color: $color-primary;
    color: $color-white;
    box-shadow: 0 1rem 2rem rgba($color-black, 0.15);
    transform: translateY(-0.2rem);
  }

  &:active {
    box-shadow: 0 0.5rem 1rem rgba($color-black, 0.15);
    transform: translateY(0);
  }
}
