@use "../abstracts/variables" as *;

.parallax {
  position: relative;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 35rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;

  @media (max-width: 900px) {
    height: 50rem;
  }

  &__content {
    z-index: 2;
    padding: 2rem;
    max-width: 1320px;

    .heading-primary {
      font-size: 2.5rem;
      color: white;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to top,
      rgba(29, 151, 108, 0.1) /* fully transparent */,
      rgba(29, 151, 108, 1) /* semi-transparent */
    );
    z-index: 1;
  }

  &::before {
    display: none;
  }
}

.paralax-buttons {
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  display: flex;
  gap: 2rem;
  width: 100%;
}
