@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.hero {
  position: relative;
  height: 35rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-white;
  text-align: center;
  margin-top: 11rem;

  @include respond(phone) {
    margin-top: 8rem;
  }

  // For background image variant
  &--image {
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;

    &::after {
      background: linear-gradient(to top, rgba(#1d976c, 1), rgba(#1d976c, 0.7));
    }
  }

  // For pattern variant
  &--pattern {
    background-color: $color-primary;
    position: relative;

    &::after {
      background: rgba(0, 0, 0, 0.3);
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    padding: 2rem 8rem;
    max-width: 80rem;

    margin: 0 auto;

    @media (max-width: 768px) {
      padding: 1.5rem;
    }
  }

  &__title {
    font-size: 4rem;
    color: $color-white;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 3.5rem;
    }

    @media (max-width: 480px) {
      font-size: 3.5rem;
    }
  }

  &__text {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: $color-white;
    letter-spacing: 2px;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 1rem;

    @media (max-width: 480px) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;

    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cpattern id='cube' patternUnits='userSpaceOnUse' width='50' height='50' patternTransform='scale(2) rotate(0)'%3E%3Crect width='50' height='50' fill='%23410179ff'/%3E%3Cpath d='M25 0L50 15L25 30L0 15zM0 15L0 35L25 50L25 30M50 15L50 35L25 50L25 30' stroke='%23ffffff' stroke-width='1' stroke-opacity='0.5' fill='none'/%3E%3C/pattern%3E%3C/defs%3E%3Crect width='100%' height='100%' fill='url(%23cube)'/%3E%3C/svg%3E");

    pointer-events: none;
    z-index: 1;
  }

  // Base overlay styles
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
