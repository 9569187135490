@use "../abstracts/variables" as *;
@use "../base/typography" as *;

.card-link {
  position: relative;
  height: 400px;
  margin-bottom: 2.8rem;
  display: block;
  border-radius: 1rem;
  overflow: hidden;
  text-decoration: none;
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

  &:hover {
    .card-link__image {
      transform: scale(1.05);
    }
    .card-link__overlay {
      background-color: rgba($color-primary, 0.8);
    }
  }

  // Card Image
  &__image {
    width: 100%;
    height: 400px;
    object-fit: cover;
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }

  // Purple Overlay
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1.5rem;
    transition: background-color 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }

  // Card Content
  &__title {
    @extend .heading-secondary;
    color: #fff;
    padding: 1rem;
    background-color: $color-primary;
    text-transform: uppercase;
    border-radius: 3px;
    font-weight: 600;
    font-size: 1.2rem;

    @media screen and (max-width: 992px) {
      font-size: 1.6rem;
    }

    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
  }

  &__cta {
    text-transform: uppercase;
    font-size: 1.1rem;
    margin-top: 0.2rem;
    font-weight: 500;
  }
}
