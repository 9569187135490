@use "../abstracts/variables" as *;

.section--form {
  padding: 6rem 0;
  background-color: #f9f9f9;
}

.contact-form {
  max-width: 60rem;
  margin: 0 auto;
  padding: 3rem;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 768px) {
    padding: 2rem;
  }

  &__group {
    position: relative;
    margin-bottom: 2.5rem;

    &:last-child {
      margin-bottom: 0;
      text-align: center;
    }
  }

  &__input {
    display: block;
    width: 100%;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    font-size: 1.6rem;
    transition: all 0.3s;

    &:focus {
      outline: none;
      border-color: $color-primary;
      box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
    }

    &:focus + .contact-form__label,
    &:not(:placeholder-shown) + .contact-form__label {
      transform: translateY(-2.5rem);
      font-size: 1.2rem;
      color: $color-primary;
    }
  }

  &__textarea {
    resize: vertical;
    min-height: 12rem;
  }

  &__label {
    position: absolute;
    left: 1rem;
    top: 1rem;
    padding: 0 0.5rem;
    background-color: #fff;
    transition: all 0.3s;
    pointer-events: none;
  }

  &__message {
    margin-top: 2rem;
    padding: 1.5rem;
    border-radius: 0.5rem;
    text-align: center;

    &--success {
      background-color: #d4edda;
      color: #155724;
      border: 1px solid #c3e6cb;
    }

    &--error {
      background-color: #f8d7da;
      color: #721c24;
      border: 1px solid #f5c6cb;

      ul {
        list-style: none;
        padding: 0;
        margin: 0.5rem 0 0;
        text-align: left;

        li {
          margin-bottom: 0.5rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
