@use "../abstracts/variables" as *;

.carousel {
  position: relative;
  max-width: 1320px;
  margin: 0 auto;
  padding: 40px 0;
  overflow: hidden;

  &__container {
    display: flex;
    transition: transform 0.3s ease-in-out;
    touch-action: pan-y pinch-zoom;
    user-select: none;
    -webkit-user-select: none;

    > * {
      flex: 0 0 100%;
      width: 100%;
      min-width: 100%;
      touch-action: none;
    }
  }

  &__slide {
    transition: transform 0.3s ease-out;
    padding: 40px;
    background: $color-primary;
    border-radius: 15px;
    color: white;
    text-align: center;

    &-author {
      color: #fff;
      font-weight: 600;
      font-size: 2.2rem;
      margin-bottom: 20px;
    }

    &-content {
      font-size: 1.3rem;
      line-height: 1.6;
      max-width: 800px;
      margin: 0 auto;
    }
  }

  &__arrow {
    position: absolute;
    top: 47.5%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f5f5f5;
    }

    svg {
      width: 24px;
      height: 24px;
      color: $color-primary;
    }

    &--prev {
      left: 30px;
    }

    &--next {
      right: 30px;
    }
  }

  &__dots {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 20px;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ddd;
      border: none;
      cursor: pointer;
      padding: 0;
      transition: background-color 0.3s;

      &.active {
        background: $color-primary;
      }

      &:hover:not(.active) {
        background: #ccc;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 20px;

    &__arrow {
      width: 40px;
      height: 40px;

      &--prev {
        left: 0;
      }

      &--next {
        right: 0;
      }
    }

    &__slide {
      padding: 20px;
      margin-right: 10px;

      &-content {
        font-size: 0.9rem;
      }
    }
  }

  @media (hover: none) and (pointer: coarse) {
    &__arrow {
      display: none;
    }
  }

  @media (max-width: 678px) {
    &__arrow {
      display: none;
    }

    &__slide {
      &-author {
        font-size: 1.7rem;
      }

      &-content {
        font-size: 1.5rem;
      }
    }
  }
}
