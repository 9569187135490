.features-grid {
  display: grid;
  gap: 1.5rem;
  width: 100%;

  // Mobile first approach (stacked layout)
  grid-template-columns: 1fr;

  // Tablet and up
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "sidebar content-1"
      "sidebar content-2"
      "sidebar content-3";

    .sidebar {
      grid-area: sidebar;
    }
  }

  // Desktop layout (matches your image)
  @media (min-width: 1024px) {
    grid-template-columns: 300px 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      "sidebar content-1"
      "sidebar content-2-3";

    .content-wrapper {
      grid-area: content-2-3;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;
    }
  }

  // Grid items styling
  .sidebar {
    position: relative;
    background: linear-gradient(
      to bottom right,
      rgba(32, 201, 151, 0.9),
      rgba(23, 162, 184, 0.9)
    );
    background-size: cover;
    background-position: center;
    border-radius: 0.5rem;
    padding: 2rem;
    color: white;
    min-height: 300px;

    // Add this class for when using background image
    &.has-bg-image {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0.5rem;
      }

      // Add gradient variations
      &.gradient-green:before {
        background: linear-gradient(
          to bottom right,
          rgba(32, 201, 151, 0.9),
          rgba(23, 162, 184, 0.9)
        );
      }

      &.gradient-purple:before {
        background: linear-gradient(
          135deg,
          rgba(78, 84, 200, 0.9),
          rgba(143, 148, 251, 0.9)
        );
      }

      // Move content above overlay
      > * {
        position: relative;
        z-index: 1;
      }
    }

    h2 {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 1.5rem;
      position: relative;
      padding-bottom: 1rem;
      line-height: 1.2;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 60px;
        height: 3px;
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .content {
    position: relative;
    background: linear-gradient(
      135deg,
      rgba(78, 84, 200, 0.9),
      rgba(143, 148, 251, 0.9)
    );
    background-size: cover;
    background-position: center;
    border-radius: 0.5rem;
    padding: 2rem;
    color: white;
    min-height: 200px;

    // Add this class for when using background image
    &.has-bg-image {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0.5rem;
      }

      // Add gradient variations
      &.gradient-green:before {
        background: linear-gradient(
          to bottom right,
          rgba(32, 201, 151, 0.9),
          rgba(23, 162, 184, 0.9)
        );
      }

      &.gradient-purple:before {
        background: linear-gradient(
          135deg,
          rgba(78, 84, 200, 0.9),
          rgba(143, 148, 251, 0.9)
        );
      }

      // Move content above overlay
      > * {
        position: relative;
        z-index: 1;
      }
    }

    &-1 {
      height: 100%;
    }

    h2 {
      font-size: 1.75rem;
      font-weight: 600;
      margin-bottom: 1.25rem;
      position: relative;
      padding-bottom: 0.75rem;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 40px;
        height: 2px;
        background: rgba(255, 255, 255, 0.5);
      }
    }

    // Default margin for larger screens
    margin-bottom: 0;

    // Add margin for smaller screens
    @media (max-width: 767px) {
      margin-bottom: 1.5rem;
    }

    // Add margin for tablet screens
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-bottom: 1.5rem;
    }

    &:last-child {
      margin-bottom: 0; // Remove margin from the last item
    }
  }
}
